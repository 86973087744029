.App {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  margin-top: 50px;
}

.main{
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.ant-pagination-options-size-changer{
  display: none;
}